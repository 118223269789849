import React from 'react';

export const VenduSVG = (props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 30" {...props}>
    <path
      fill="#3A3FDE"
      d="M17.343 3.272C13.119-.123 8.67-1.468 5.766 2.14c-7.562 9.488-7.996 12.308.546 21.291-2.116-4.129 4.18-14.854 11.03-20.159z"
    />
    <path
      fill="#5174F2"
      d="M28.919 2.14c-2.862-3.607-7.354-2.263-11.577 1.132 6.85 5.305 13.146 16.03 11.03 20.16 8.542-8.984 8.108-11.804.547-21.292z"
    />
    <path
      fill="#111DD1"
      d="M28.373 23.431l-.434.46c-5.203 5.305-8.541 5.703-10.597 5.703-2.055 0-5.41-.424-10.597-5.703l-.433-.46c-2.116-4.129 4.18-14.854 11.03-20.159 6.851 5.305 13.147 16.03 11.03 20.16z"
    />
  </svg>
);

export const SbaSVG = (props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 41" {...props}>
    <circle cx={20.5} cy={20.5} r={20.5} fill="#809EEE" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M19.588 11.154c-5.199 0-9.429 4.221-9.429 9.41 0 5.188 4.23 9.409 9.43 9.409 5.199 0 9.428-4.221 9.428-9.41 0-5.188-4.23-9.409-9.429-9.409zm0 20.974C13.198 32.128 8 26.94 8 20.564 8 14.187 13.199 9 19.588 9c6.39 0 11.588 5.187 11.588 11.564 0 6.376-5.198 11.564-11.588 11.564z"
      clipRule="evenodd"
    />
    <path
      fill="#5174F2"
      fillRule="evenodd"
      d="M35.995 19.384c-.621-8.21-7.189-14.762-15.416-15.38A1.193 1.193 0 0019.3 5.19l-.043 14.278a1.194 1.194 0 001.195 1.196l14.352-.003c.693 0 1.245-.587 1.193-1.277z"
      clipRule="evenodd"
    />
    <path
      fill="#1F00DB"
      fillRule="evenodd"
      d="M15.94 20.223a3.292 3.292 0 003.29 3.294 3.293 3.293 0 003.302-3.282 3.292 3.292 0 00-3.29-3.295 3.292 3.292 0 00-3.302 3.282z"
      clipRule="evenodd"
    />
  </svg>
);

export default {
  VenduSVG,
  SbaSVG,
};
