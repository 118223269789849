import React from 'react';

export const FacebookSVG = (props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <circle cx={15} cy={15} r={15} fill="#fff" />
      <path
        fill="#3C4761"
        d="M19.008 16l.444-2.896h-2.778v-1.879c0-.792.388-1.564 1.632-1.564h1.263V7.196S18.423 7 17.327 7c-2.288 0-3.784 1.387-3.784 3.898v2.206H11V16h2.543v7h3.13v-7h2.335z"
      />
    </svg>
  );
};

export const LinkedInSVG = (props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <circle cx={15} cy={15} r={15} fill="#fff" />
      <path
        fill="#3C4761"
        d="M8.25 22.2h2.7v-9.99h-2.7v9.99zM9.6 7.8c-.9 0-1.62.72-1.62 1.62 0 .9.72 1.62 1.62 1.62.9 0 1.62-.72 1.62-1.62 0-.9-.72-1.62-1.62-1.62zm5.94 5.94v-1.53h-2.7v9.99h2.7v-5.13c0-2.88 3.69-3.06 3.69 0v5.13h2.7v-6.12c0-4.86-5.13-4.68-6.39-2.34z"
      />
    </svg>
  );
};

export const InstagramSVG = (props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" {...props}>
      <circle cx={15} cy={15} r={15} fill="#fff" />
      <path
        fill="#3C4761"
        d="M14.998 11.999A3.008 3.008 0 0011.996 15a3.008 3.008 0 003.002 3.001 3.008 3.008 0 003-3.001 3.008 3.008 0 00-3-3.001zM23.999 15c0-1.243.011-2.474-.058-3.715-.07-1.441-.4-2.72-1.453-3.774-1.056-1.056-2.332-1.382-3.773-1.452-1.243-.07-2.475-.058-3.715-.058-1.243 0-2.475-.012-3.715.058-1.441.07-2.72.399-3.774 1.452C6.455 8.567 6.13 9.844 6.06 11.285c-.07 1.243-.058 2.474-.058 3.715 0 1.24-.012 2.474.058 3.715.07 1.441.399 2.72 1.452 3.774 1.056 1.055 2.333 1.382 3.774 1.452 1.243.07 2.474.058 3.715.058 1.243 0 2.474.012 3.715-.058 1.44-.07 2.72-.399 3.773-1.452 1.056-1.056 1.383-2.333 1.453-3.774.072-1.24.058-2.472.058-3.715zm-9.001 4.618A4.611 4.611 0 0110.38 15a4.611 4.611 0 014.618-4.618A4.611 4.611 0 0119.615 15a4.611 4.611 0 01-4.617 4.618zm4.807-8.346a1.077 1.077 0 01-1.079-1.079c0-.597.482-1.078 1.079-1.078a1.077 1.077 0 01.412 2.075c-.13.054-.27.082-.412.082z"
      />
    </svg>
  );
};

export default {
  FacebookSVG,
  LinkedInSVG,
  InstagramSVG,
};
