import Link from 'next/link';
import React from 'react';
import { FacebookSVG, LinkedInSVG } from './Icons';

import styles from './styles.scss';

const Footer = (): JSX.Element => {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.section}>
          <div className={styles.content}>

            <div className={styles.links}>
              <nav>
                <h2>Vendu</h2>
                <ul>
                  <li><Link href="/about"><a>Om oss</a></Link></li>
                </ul>
              </nav>
              <nav>
                <h2>Vilkår og personvern</h2>
                <ul>
                  <li><Link href="/terms"><a>Vilkår</a></Link></li>
                  <li><Link href="/privacy"><a>Personvern</a></Link></li>
                </ul>
              </nav>
              <nav className={styles.socialLinks}>
                <h2>Følg vendu</h2>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/vendu.no/" rel="noopener noreferrer" target="_blank">
                      <FacebookSVG />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/vendu/" rel="noopener noreferrer" target="_blank">
                      <LinkedInSVG />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className={styles.footnote}>
            <p>
              Tjenesten er drevet av Vendu AS (org. nr. 917 071 373). Vendu bruker maskinlæring og data for å skape digital bolig intelligens som bidrar til mer bærekraftig boliger og tryggere bolighandel. Vår ledestjerne er å hjelpe mennesker å ta gode valg, og vår oppgave er å gjøre bolighandel og bolighold tryggere og enklere.
            </p>
            <p>
              Alle rettigheter er forbeholdt Vendu © 2021, Vendu AS
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
