import Link from 'next/link';
import React from 'react';
import styles from './styles.scss';

const LoginState = (): JSX.Element => {
  return (
    <>
      <Link href="/signin">
        <a className={styles.link}>Logg inn</a>
      </Link>
    </>
  );
};

export default LoginState;
