import classNames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useState, useRef } from 'react';
import Placeholder from './Placeholder';
import styles from './styles.scss';

interface Props {
  user: any;
}

const LogoutState = ({ user }: Props): JSX.Element => {

  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleSetOpen = (): void => {
    setOpen(!open);
  }

  const handleClickOutside = (e): void => {
    if (!dropdownRef.current.contains(e.target) && !buttonRef.current.contains(e.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.dropdown} ref={dropdownRef}>
        <button className={styles.resetButtonStyle} onClick={handleSetOpen} ref={buttonRef}>
          <Placeholder className={styles.placeholder} />
        </button>
        <div className={classNames(styles.dropdownContent, open && styles.show)}>
          <ul>
            <li className={styles.name}>
              <span>{user?.attributes?.name}</span>
              <span>{user?.attributes?.email}</span>
            </li>
            <li><Link href="/my-page"><a className={styles.myPageLink}>Min side</a></Link></li>
            <li><Link href="/signout"><a className={classNames(styles.link, styles.logout)}>Logg ut</a></Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LogoutState;
