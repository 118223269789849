import classNames from 'classnames';
import Footer from 'components/common/Footer';
import LoginLogoutWidget from 'components/common/LoginLogoutWidget';
import Link from 'next/link';
import React from 'react';
import { VenduSVG, SbaSVG } from './Icons';
import styles from './styles.scss';

interface Props {
  showBoxShadow?: boolean;
  children: React.ReactNode;
  backgroundColor?: 'white' | 'primary';
}

const LoginLogoutLayout = ({ showBoxShadow, children, backgroundColor }: Props): JSX.Element => (
  <div className={classNames(styles.container, backgroundColor === 'primary' && styles.primaryBackgroundColor)}>
    <header className={classNames(styles.topbar, showBoxShadow && styles.boxShadow)}>
      <div className={styles.vendu_link}>
        <Link href="/"><a><VenduSVG /></a></Link>
        <Link href="/"><a className={classNames(styles.searchLink, styles.navigationLink)}>Søk etter bolig</a></Link>
      </div>

      <div className={styles.centeredContent}>
        <div className={styles.sba}>
          <SbaSVG />
          <p>
            <span>Smart&nbsp;</span>
            <span>Boliganalyse</span>
          </p>
        </div>
      </div>

      <div className={styles.rightContent}>
        <Link href="/my-page">
          <a className={styles.navigationLink}>Min side</a>
        </Link>
        <LoginLogoutWidget className={styles.loginLogoutWidget} />
      </div>
    </header>
    <main className={styles.main}>
      {children}
    </main>
    <Footer />
  </div>
);

LoginLogoutLayout.defaultProps = {
  title: null,
};

export default LoginLogoutLayout;
