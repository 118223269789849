import { Auth } from 'aws-amplify';
import React, { useState, useEffect } from 'react';

import LoginState from './LoginState';
import LogoutState from './LogoutState';

interface Props {
  className?: string;
}

const LoginLogoutWidget = ({ className }: Props): JSX.Element => {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getCurrentUser = async (): Promise<void> => {
    const usr = await Auth.currentUserInfo();
    setUser(usr);
    setLoading(false);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (loading) {
    return null;
  }

  return <div className={className}>{user ? <LogoutState user={user} /> : <LoginState />}</div>;
};

export default LoginLogoutWidget;
