import React from 'react';

const Placeholder = (props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
      <circle cx={20} cy={20} r={20} fill="#558EFC" />
      <path
        fill="#fff"
        fillOpacity={0.5}
        d="M15.75 13.737c0 2.611 2.131 4.737 4.75 4.737s4.75-2.126 4.75-4.737C25.25 11.125 23.119 9 20.5 9s-4.75 2.125-4.75 4.737zM28.944 29H30v-1.053c0-4.062-3.316-7.368-7.389-7.368H18.39c-4.075 0-7.389 3.306-7.389 7.368V29h17.944z"
      />
    </svg>
  );
};

export default Placeholder;
